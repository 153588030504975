@import '../../styles//variables';
.input{
    width: 100%;
    margin-bottom: $spacer;
}
input{
    width: 100%;
    height: 25px;
}
[type='submit']{
    width: 50%;
    height: 40px;
}
