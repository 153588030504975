@import "../../styles/variables";

.tagForm{
  width: 60%;
  display: flex;
  justify-content: space-around
}
.actionContainer{
  display: flex;
  justify-content: space-between
}
.info {
  margin-bottom: $spacer;
}
.input{
  width: 60%;
}
.submit{
  min-width: 200px;
}
tr{
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin: auto $spacer;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

