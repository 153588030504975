@import "../../styles/variables";

.container {
  margin-top: 2 * $spacer;
  margin-bottom: 2 * $spacer;
  padding: $spacer;
  border-width: 1px;
  border-color: $light;
  border-style: solid;
  border-radius: 5px;
  
}
.info {
  margin-bottom: $spacer;
}
.actionContainer{
  width: 30%;
  display: flex;
  justify-content: space-between

}

.input{
  margin-bottom: $spacer;
}
.inputSmall{
  @extend .input;
  width: 20%;
}
.submit{
  min-width: 200px;
}
.pageLayoutContainer{
  display: flex;
  margin-bottom: 2*$spacer;

}
.checkbox input{
  width: 20px !important;
  margin:  0 !important;
}
.checkbox label{
  margin: 0 $spacer;
}
.tooltip {
  position: relative;
  display: inline-block;
  margin: auto $spacer;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

